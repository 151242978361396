export const hamburger = () => {
  // メニュー展開時に背景を固定
  const backgroundFix = (bool) => {
    const scrollingElement = () => {
      const browser = window.navigator.userAgent.toLowerCase();
      if ("scrollingElement" in document) return document.scrollingElement;
      return document.documentElement;
    };

    const scrollY = bool
      ? scrollingElement().scrollTop
      : parseInt(document.body.style.top || "0");

    const fixedStyles = {
      height: "100vh",
      position: "fixed",
      top: `${scrollY * -1}px`,
      left: "0",
      width: "100vw",
    };

    Object.keys(fixedStyles).forEach((key) => {
      document.body.style[key] = bool ? fixedStyles[key] : "";
    });

    if (!bool) {
      window.scrollTo(0, scrollY * -1);
    }
  };

  // 変数定義
  const CLASS = "-active";
  let flg = false;

  let hamburger = document.getElementById("js-hamburger");

  // 該当要素がなければ離脱
  if(!hamburger) return;

  let hamburgerWrap = hamburger.closest("div");
  let focusTrap = document.getElementById("js-focus-trap");
  let menu = document.getElementById("js-nav-area");

  // メニュー開閉制御
  hamburgerWrap.addEventListener("click", (e) => {
    //ハンバーガーボタンが選択されたら
    hamburgerWrap.classList.toggle(CLASS);
    menu.classList.toggle(CLASS);

    if (flg) {
      // flgの状態で制御内容を切り替え
      backgroundFix(false);
      hamburger.setAttribute("aria-expanded", "false");
      hamburger.focus();
      flg = false;
    } else {
      backgroundFix(true);
      hamburger.setAttribute("aria-expanded", "true");
      flg = true;
    }
  });

  window.addEventListener("keydown", () => {
    //escキー押下でメニューを閉じられるように
    if (event.key === "Escape") {
      hamburgerWrap.classList.remove(CLASS);
      menu.classList.remove(CLASS);

      backgroundFix(false);
      hamburger.focus();
      hamburger.setAttribute("aria-expanded", "false");
      flg = false;
    }
  });

  if (!focusTrap) return;
  // フォーカストラップ制御
  focusTrap.addEventListener("focus", (e) => {
    hamburger.focus();
  });
};
