class WindowControl {
  constructor() {
    this.init();
    this.flag = false;
  }

  init() {
    window.addEventListener("keydown", (e) => {
      if (this.flag) {
        if (e.code === "ArrowDown") {
          e.preventDefault();
        }
        if (e.code === "ArrowUp") {
          e.preventDefault();
        }
      }
    });
  }
}

export default new WindowControl();
