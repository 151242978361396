export const AddClassOnScroll = () => {
  window.addEventListener("load", () => {
    // 要素の選択
    //その要素自体にアニメーションを設定する際はjs-animtionとu-animationを使用。js-animation-wrapを適用する親要素が存在しない時などに使用。
    const elements = document.querySelectorAll(".js-animation");
    //その要素の直下の子要素すべてにアニメーションを設定する場合はjs-animation-wrapとu-animation-wrapを使用。コンテンツエリアなど、アニメーションさせる要素が多い状況で使用。
    const elementsWrap = document.querySelectorAll(".js-animation-wrap > *");

    // オプションの設定
    const options = {
      root: null, // ビューポートをルートとして設定
      rootMargin: "0px",
      threshold: 0.1, // 要素の10%が見えたらcallbackを実行
    };

    // IntersectionObserver のコールバック定義
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        // 要素がビューポートに入った場合
        if (entry.isIntersecting) {
          entry.target.classList.add("u-animation--active");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    // すべての要素を監視開始
    elements.forEach((element) => {
      observer.observe(element);
    });
    elementsWrap.forEach((element) => {
      observer.observe(element);
    });
  });
};
