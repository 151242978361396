export class TableStyle {
  constructor(table) {
    this.table = table;
    this.init();
  }

  init() {
    const TableThead = this.table.querySelector("thead");
    if (TableThead) {
      this.table.classList.add("wp-block-table--fixed");
    }
  }
}
