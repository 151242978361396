import Screen from '../util/Screen';

export class PageTopScroll {
  constructor(obj) {
    this.pageTopButton = obj.pageTopButton;
    this.footer = document.querySelector('.js-footer');
    this.init();
  }

  // スクロールイベントを登録する
  init() {
    if (Screen.getBP(Screen.M)) {
      Screen.addScrollObject(this);
      this.onFixed();
    }
  }

  // スクロール位置によってボタンの表示・非表示を切り替える
  onScroll() {
    if (Screen.scrollTop > 300) {
      this.pageTopButton.classList.add('-show');
    } else {
      this.pageTopButton.classList.remove('-show');
    }
    this.onFixed();
  }

  // フッターに到達したらボタンを固定する
  onFixed() {
    const footerTop = this.footer.getBoundingClientRect().top;

    if (window.innerHeight - footerTop >= 0) {
      this.pageTopButton.classList.add('-fixed');
    } else {
      this.pageTopButton.classList.remove('-fixed');
    }
  }
}
