export const pulldown = () => {
  // 変数定義
  const CLASS = "-active";
  let flg = false;

  let pulldown = document.querySelectorAll('[js-nav-pulldown]');

  if (!pulldown) return;

  // メニュー開閉制御
  Array.from(pulldown).map( target=>{
    // メニュー展開
    target.setAttribute("aria-expanded", "false");

    target.addEventListener("mouseover", (e) => {
      e.stopPropagation();

      if (flg) return;
      target.classList.add(CLASS);
      target.setAttribute("aria-expanded", "true");
      flg = true;
    });

    // メニュー閉鎖
    target.addEventListener("mouseout", (e) => {
      e.stopPropagation();

      if (!flg) return;
      target.classList.remove(CLASS);
      target.setAttribute("aria-expanded", "false");
      flg = false;
    });
  });
};
