/**
 * 全角英数字記号を半角に変換
 * @param {string} str
 * @returns {string} 半角英数字 ああ１；>>>ああ1;
 */
const hankaku2Zenkaku = (str) => {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
};

/**
 * 数字を3桁に区切る
 * @param {string | number} numString 文字型の数字
 * @returns {string} 1000 >>> 1,000;
 */
const thousandsSeparator = (numString) => {
  let toString = numString.toString();
  toString = hankaku2Zenkaku(toString);
  const replaceString = toString.replace(/[^0-9.]/g, "");
  return replaceString ? Number(replaceString).toLocaleString() : "";
};

const targetString = (value) => {
  return value.replace(/[ぁ-ん]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) + 0x60);
  });
};

export default {
  thousandsSeparator,
  hankaku2Zenkaku,
  targetString,
};
