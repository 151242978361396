import utils from "../util";
import WindowControl from "../util/WindowControl";

export default function selectBox(element) {
  const selectBox = element;
  const selectSubject = element.querySelector(".js-select-subject");
  const selectAccordion = element.querySelector(".js-select-accordion");
  const selectAccordionMultiple = element.querySelector(
    ".js-select-accordion.-multiple"
  );
  const selectWindow = element.querySelector(".js-select-multiple-window");
  const selectSearch = element.querySelector(".js-select-search");
  const portal = document.querySelector("body");
  let selectItem = selectAccordion.querySelectorAll(
    ".js-select-list li:not(.-disabled):not(.js-select-add-add-area)"
  );
  let highlightNum = null;
  let openFlag = false;
  let ImeChecker = false;
  let searchFlg = true;
  let itemArray = [];

  const onMounted = () => {
    // portal.appendChild(selectAccordion);
    if (selectSubject.value) {
      for (let i = 0; i < selectItem.length; i++) {
        if (selectItem[i].textContent === selectSubject.value) {
          selectItem[i].classList.add("-current");
          selectItem[i].classList.remove("-focus");
          highlightNum = i;
          // onScroll();
          break;
        }
      }
    }
  };

  const onScroll = () => {
    setTimeout(() => {
      if (selectItem[highlightNum]) {
        selectItem[highlightNum].scrollIntoView({
          block: "nearest",
          inline: "nearest",
        });
      }
    });
  };
  const setMultipleItem = () => {
    selectWindow.innerHTML = "";
    itemArray.forEach((item) => {
      const li = document.createElement("li");
      const button = document.createElement("button");
      li.classList.add("p-select__multiple-item");

      const prefix = selectAccordion.querySelector(".js-select-prefix");

      li.innerHTML = "<span>" + item + "</span>";

      button.classList.add("js-select-close");
      button.classList.add("p-select__close");
      li.appendChild(button);

      if (prefix) {
        li.prepend(prefix.cloneNode(true));
      }
      selectWindow.appendChild(li);
      clickMultipleClose();
    });
  };
  const clickMultipleClose = () => {
    if (selectWindow !== null) {
      const close = selectWindow.querySelectorAll(".js-select-close");
      close.forEach((elm) => {
        elm.addEventListener("click", (e) => {
          let currentValue = e.currentTarget
            .closest("li")
            .querySelector("span").textContent;
          e.currentTarget.closest("li").remove();
          itemArray = itemArray.filter(function (item) {
            return item !== currentValue;
          });
          selectAccordionMultiple
            .querySelectorAll("li label")
            .forEach((elm2) => {
              let value = elm2.textContent;
              value = value.replace(/\s+/g, "");
              if (value === currentValue) {
                elm2.querySelector("input").checked = false;
              }
            });
        });
      });
    }
  };
  const selectMultipleValue = () => {
    if (selectAccordionMultiple !== null) {
      selectAccordionMultiple.querySelectorAll("li input").forEach((elm) => {
        elm.addEventListener("click", (e) => {
          e.stopPropagation();
          let value = e.currentTarget
            .closest("li")
            .querySelector("label").textContent;

          const prefix = e.currentTarget
            .closest("li")
            .querySelector(".js-select-prefix");
          if (prefix) {
            value = value.replace(prefix.textContent, "");
          }

          value = value.replace(/\s+/g, "");
          let checkArray = itemArray.includes(value);
          if (checkArray == false) {
            itemArray.push(value);
          } else {
            itemArray = itemArray.filter(function (item) {
              return item !== value;
            });
          }
          setMultipleItem();
        });
      });
    }
  };
  const onClickSelect = () => {
    selectSubject.addEventListener("click", (e) => {
      openFlag = !openFlag;
      togglePopup(openFlag);
    });
  };

  const onKeyupSelect = (element) => {
    element.addEventListener("keyup", (e) => {
      if (e.code === "Tab" && searchFlg) {
        if (!openFlag) {
          openFlag = true;
          togglePopup(openFlag);
        }
      } else {
        searchFlg = true;
      }

      if (e.code === "ArrowUp") {
        if (openFlag) {
          highlightNum =
            highlightNum === null
              ? 0
              : highlightNum > 0
              ? highlightNum - 1
              : selectItem.length - 1;
          onHighlightItem();
        } else {
          openFlag = true;
          togglePopup(openFlag);
        }
      }

      if (e.code === "ArrowDown") {
        if (openFlag) {
          highlightNum =
            highlightNum === null
              ? 0
              : selectItem.length - 2 >= highlightNum
              ? highlightNum + 1
              : 0;
          onHighlightItem();
        } else {
          openFlag = true;
          togglePopup(openFlag);
        }
      }
      if (
        (e.code === "Enter" && ImeChecker) ||
        (e.code === "Space" && ImeChecker)
      ) {
        ImeChecker = false;
      }
    });

    element.addEventListener("keydown", (e) => {
      if (e.code === "Tab") {
        openFlag = false;
        ImeChecker = false;
        if (
          e.shiftKey &&
          event.key === "Tab" &&
          e.currentTarget === selectSearch
        ) {
          searchFlg = false;
          togglePopup(openFlag, false);
        } else if (document.querySelector(".js-select-add-add-area")) {
          document.querySelectorAll(".js-add-item-add").forEach((submit) => {
            submit.addEventListener("keyup", (e) => {
              if (e.code === "Tab") {
                togglePopup(openFlag);
              }
            });
          });
        } else {
          togglePopup(openFlag);
        }
      }
      if (
        e.code === "Enter" &&
        !ImeChecker &&
        highlightNum !== null &&
        e.code !== "Space" &&
        !e.isComposing &&
        openFlag
      ) {
        setValue(selectItem[highlightNum]);
      }
    });
  };

  const togglePopup = (flag, searchFocus = true) => {
    if (flag) {
      if (searchFocus === false) {
        selectSubject.blur();
      }
      if (!selectSubject.value) {
        highlightNum = null;
        if (selectAccordion.querySelector(".-current")) {
          selectAccordion
            .querySelector(".-current")
            .classList.remove("-current");
        }
        if (selectAccordion.querySelector(".-focus")) {
          selectAccordion.querySelector(".-focus").classList.remove("-focus");
        }
      }

      selectAccordion.classList.add("-show");
      selectSubject.classList.add("-focus");

      selectAccordion.style.maxWidth = `${selectBox.clientWidth + 2}px`;
      selectAccordion.style.top = `${selectSubject.clientHeight}px`;

      window.addEventListener("mousedown", clickOutSide);
      WindowControl.flag = true;

      if (selectSearch && searchFocus) {
        setTimeout(() => {
          selectSearch.focus();
        }, 100);
      }
    } else {
      WindowControl.flag = false;
      selectAccordion.classList.remove("-show");
      selectSubject.classList.remove("-focus");
      if (selectSearch) {
        selectSearch.value = "";
        selectItem = selectAccordion.querySelectorAll(
          ".js-select-list li:not(.js-select-add-add-area)"
        );
        for (let i = 0; i < selectItem.length; i++) {
          selectItem[i].style.display = "flex";
          selectItem[i].classList.remove("-disabled");
        }
      }
      window.removeEventListener("mousedown", clickOutSide);
      ImeChecker = false;
    }
  };

  const onHighlightItem = () => {
    for (let i = 0; i < selectItem.length; i++) {
      const item = selectItem[i];

      if (i === highlightNum) {
        item.classList.add("-focus");
      } else {
        item.classList.remove("-focus");
      }
    }
    onScroll();
  };

  const setValue = (item) => {
    openFlag = false;
    togglePopup(openFlag);
    if (selectSubject.value !== item.textContent) {
      selectSubject.setAttribute("value", item.textContent);
      // selectSubject.onchange();
      onScroll();
    } else {
      selectSubject.setAttribute("value", item.textContent);
      highlightNum = null;
    }

    // 値の挿入後にchangeイベントを発火
    let e = new Event('change');
    selectSubject.dispatchEvent(e);

    selectItem = selectAccordion.querySelectorAll(
      ".js-select-list li:not(.js-select-add-add-area)"
    );

    for (let j = 0; j < selectItem.length; j++) {
      if (selectSubject.value === selectItem[j].textContent) {
        selectItem[j].classList.add("-current");
      } else {
        selectItem[j].classList.remove("-focus");
        selectItem[j].classList.remove("-current");
      }
    }
    // selectSubject.focus();
  };

  const onClickValue = () => {
    if (selectAccordion.classList.contains("-multiple") !== true) {
      selectAccordion.addEventListener("click", (e) => {
        const item = e.target;
        if (
          item.tagName.toLowerCase() === "li" &&
          item.classList.contains("js-select-add-add-area") !== true
        ) {
          setValue(item);
        }
      });
    }
  };

  const onSearch = () => {
    selectSearch.addEventListener("input", (e) => {
      selectItem = selectAccordion.querySelectorAll(
        ".js-select-list li:not(.js-select-add-add-area)"
      );

      for (let i = 0; i < selectItem.length; i++) {
        const wordA = utils.targetString(
          utils.hankaku2Zenkaku(selectItem[i].textContent)
        );
        const wordB = utils.targetString(
          utils.hankaku2Zenkaku(e.currentTarget.value)
        );
        if (wordA.match(wordB)) {
          selectItem[i].style.display = "flex";
          selectItem[i].classList.remove("-disabled");
        } else {
          selectItem[i].style.display = "none";
          selectItem[i].classList.add("-disabled");
        }
      }

      selectItem = selectAccordion.querySelectorAll(
        ".js-select-list li:not(.-disabled):not(.js-select-add-add-area)"
      );

      highlightNum = selectItem.length > 0 ? 0 : null;
      if (highlightNum !== null) {
        onHighlightItem();
      }
    });

    selectSearch.addEventListener(
      "compositionstart",
      () => {
        ImeChecker = true;
      },
      false
    );
    onKeyupSelect(selectSearch);
  };

  const clickOutSide = (e) => {
    const flag = selectSearch
      ? !selectBox.contains(e.target) && !selectSearch.contains(e.target)
      : !selectBox.contains(e.target);
    if (flag) {
      if (openFlag) {
        openFlag = false;
        togglePopup(openFlag);
      }
    }
  };
  selectMultipleValue();
  clickMultipleClose();
  onMounted();
  onKeyupSelect(selectSubject);
  onClickSelect();
  onClickValue();
  if (selectSearch) {
    onSearch();
  }
}

export function initSelectBox(parent) {
  const selectBoxList = parent.querySelectorAll(".js-select-box");
  for (let i = 0; i < selectBoxList.length; i++) {
    selectBox(selectBoxList[i]);
  }
}
