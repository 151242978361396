import Screen from '../util/Screen';

export class ScrollHeader {
  constructor(obj) {
    this.header = obj.header;
    this.startPosition = Screen.scrollTop;
    this.init();
    this.timer = 0;
  }

  init() {
    Screen.addScrollObject(this);

    if (Screen.scrollTop > 10 && Screen.getBP(Screen.L)) {
      this.header.classList.add('-fixed');
    }
  }

  onScroll() {
    if (Screen.getBP(Screen.L)) {
      clearTimeout(this.timer);
      const upBoolean = this.startPosition > Screen.scrollTop;
      if (Screen.scrollTop > 10) {
        this.header.classList.add('-hide');
      } else {
        this.header.classList.remove('-hide');
      }

      if (upBoolean && Screen.scrollTop > 10) {
        this.header.classList.add('-fixed');
      } else {
        this.header.classList.remove('-fixed');
      }

      this.timer = setTimeout(() => {
        this.startPosition = Screen.scrollTop;
      }, 60);
    }
  }
}
