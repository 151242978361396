import Screen from "../util/Screen";

const CLASSLIST = {
  OPEN: "-open",
};

export class Header {
  constructor() {
    this.openFlag = false;
    this.menuTrigger = document.querySelector("#js-mobile-menu-trigger");
    this.header = document.querySelector("#js-header");
    this.headerNav = this.header.querySelector("#js-header-nav");
    this.body = document.querySelector(".js-container");

    if (!this.header && !this.headerNav) return false;
    this.init();
  }

  init() {
    Screen.addResizeObject(this);

    this.menuTrigger?.addEventListener("click", () => {
      this.header?.classList.toggle(CLASSLIST.OPEN);
      this.menuTrigger?.classList.toggle(CLASSLIST.OPEN);
      this.headerNav?.classList.toggle(CLASSLIST.OPEN);
    });
  }

  onResize() {
    if (Screen.getBP(Screen.M)) {
      this.menuTrigger?.classList.remove(CLASSLIST.OPEN);
      this.headerNav?.classList.remove(CLASSLIST.OPEN);
      this.header?.classList.remove(CLASSLIST.OPEN);
    }
  }
}
