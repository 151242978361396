export const SwitchHeaderClass = () => {
  const nav = document.querySelector("#js-nav-area");
  if (!nav) return;
  function switchHeaderClass() {
    if (window.outerWidth < 768) {
      setTimeout(() => {
        nav.classList.add("-sp");
      }, 500);
    } else {
      nav.classList.remove("-sp");
    }
  }
  addEventListener("resize", switchHeaderClass, false);
  switchHeaderClass();
};
